import "./App.css";

import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import {
  NavLink,
  Navigate,
  Route,
  HashRouter as Router,
  Routes,
  useParams,
} from "react-router-dom";
import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { isTabletOrMobile } from "react-responsive";
import { useNavigate } from "react-router-dom";

const formatDate = (dateString) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

function Menu({ menu }) {
  let { id } = useParams();
  let beerMenu = menu.length && menu.find((m) => m.id == id);

  return (
    <div>
      {beerMenu && (
        <>
          <header className="beer-menu-name">
            <h2>{beerMenu.name}</h2>
            Updated on {formatDate(beerMenu.updated_at)}
          </header>
          <main>
            <Sections sections={beerMenu.sections} />
          </main>
        </>
      )}
    </div>
  );
}

function MenuItem({ item }) {
  return (
    <div className="beer-menu-item">
      <div className="beer-menu-item__meta beer-info">
        <span className="beer-info__name">{`${item.name}`}</span>{" "}
        <span className="beer-menu-item__detail">{` ${item.brewery}`}</span>
      </div>
      <div className="beer-menu-item__meta beer-style">
        <span className="beer-menu-item__detail">{item.style}</span>
      </div>
      <div className="beer-menu-item__meta beer-abv">
        <span className="beer-menu-item__detail">{`${item.abv}%`}</span>
      </div>
      <div className="beer-menu-item__meta beer-prices">
        <span className="beer-menu-item__detail">
          {item.containers.map((container, index) => (
            <span key={index}>
              {container.container_size && container.container_size.name + " "}
              {container.price}
              {item.containers.length > 1 &&
              index !== item.containers.length - 1
                ? ", "
                : ""}
            </span>
          ))}
        </span>
      </div>
    </div>
  );
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    brewery: PropTypes.string.isRequired,
    style: PropTypes.string.isRequired,
    abv: PropTypes.number.isRequired,
    containers: PropTypes.arrayOf(
      PropTypes.shape({
        container_size: PropTypes.object,
        price: PropTypes.string.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

function Sections({ sections }) {
  sections = sections.filter((section) => section.public === true);

  return (
    <Accordion className="beer-menu-sections" allowZeroExpanded>
      {sections &&
        sections.map((section) => (
          <>
            {/* {!isTabletOrMobile && ( */}
            <AccordionItem key={section.id}>
              <AccordionItemHeading className="beer-menu-section">
                <AccordionItemButton>
                  <span>
                    {section.name} ({section.items.length} BEERS)
                  </span>
                  <span className="beer-menu-section__arrow">&#8227;</span>
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <div className="beer-menu-item beer-menu-item--title">
                  <div className="beer-menu-item__meta beer-info">NAME</div>
                  <div className="beer-menu-item__meta beer-style">STYLE</div>
                  <div className="beer-menu-item__meta beer-abv">ABV</div>
                  <div className="beer-menu-item__meta beer-prices">PRICE</div>
                </div>
                {section &&
                  section.items.map((item) => <MenuItem item={item} />)}
              </AccordionItemPanel>
            </AccordionItem>
          </>
        ))}
    </Accordion>
  );
}

function Section({ menu }) {
  let { id, sectionId } = useParams();
  let navigate = useNavigate();

  let beerMenu = menu.length && menu.find((menu) => menu.id == id);

  let section =
    beerMenu && beerMenu.sections.find((section) => section.id == sectionId);

  return (
    <>
      {isTabletOrMobile && (
        <NavLink to={`/${id}`}>
          <h3 onClick={() => navigate(`/${id}`)}>&lt; Sections</h3>
        </NavLink>
      )}
      {section && (
        <div>
          <>
            <h3>{section.name}</h3>
            <div className="beer-menu-item beer-menu-item--title">
              <div className="beer-menu-item__meta beer-info">NAME</div>
              <div className="beer-menu-item__meta beer-style">STYLE</div>
              <div className="beer-menu-item__meta beer-abv">ABV</div>
              <div className="beer-menu-item__meta beer-prices">PRICE</div>
            </div>
            {/* {section && section.items.map((item) => <MenuItem item={item} />)} */}
            {Array.isArray(section.items) &&
              section.items.map((item, index) => (
                <MenuItem key={index} item={item} />
              ))}
          </>
        </div>
      )}
    </>
  );
}

function App() {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [menu, setMenu] = useState({});

  useEffect(() => {
    // fetch("./menu_cache.json")
    fetch("https://el-bait-shop-beer-menu.pages.dev/api/beermenus")
      .then((res) => res.json())
      .then(
        (result) => {
          setIsLoaded(true);
          setMenu(result);
        },
        (error) => {
          setIsLoaded(true);
          setError(error);
        }
      );
  }, []);

  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else {
    return (
      <Router>
        <div>
          <div className="beer-menu-nav">
            <ul>
              {menu.length &&
                menu.map((menu) => (
                  <li key={menu.id}>
                    <NavLink
                      to={"/" + menu.id}
                      className={({ isActive }) =>
                        "" + (isActive ? " activeNavLink" : "")
                      }
                    >
                      {menu.name}
                    </NavLink>
                  </li>
                ))}
            </ul>
          </div>
          <Routes>
            <Route path="/" element={<Navigate to={`/890`} />} />
            <Route path="/:id" element={<Menu menu={menu} />} />
            <Route
              path="/:id/sections/:sectionId"
              element={<Section menu={menu} />}
            />
            <Route path="/api/beermenus" element={<Navigate to={`/api/beermenus`} />} />
          </Routes>
        </div>
      </Router>
    );
  }
}

export default App;
